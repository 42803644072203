.card-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  margin-bottom: 1rem;
  padding: 1rem;
  width: 450px;
}

.box-shadow {
  box-shadow:
    0 0px 3px rgba(0, 0, 0, 0.1),
    0 2px 8px rgba(0, 0, 0, 0.2),
    0 0px 10px rgba(0, 0, 0, 0.1),
    0 -5px 10px rgba(0, 0, 0, 0.1);
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 60%;
  color: rgb(7, 229, 7);
}

.cardheader {
  display: flex;
  justify-content: end;
  padding: 1rem;
  background: #179bee;
  margin-bottom: 10px;
  color: white;
  position: relative;
}

.cardheader>div {
  font-size: small;
  font-weight: 700;
}

.card-img {
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}


.card-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  word-wrap: break-word;
  height: 8rem;
}

.card-item h4 {
  margin-bottom: 0.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.card-item p {
  margin-bottom: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.blog-container {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  margin-top: 25px;
}

.all-blog {
  margin: 4rem;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-evenly;
  align-items: center;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


@media (max-width: 768px) {
  .blog-container {
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  .all-blog {
    display: flex;
    flex-wrap: wrap;
  }
  .card-container {
    width: 400px;
  }

}

@media (max-width: 430px) {
  .all-blog {
    margin: 1rem;
    display: block;

  }

  .blog-container {
    padding: 10px;
  }

  .card-container {
    width: 100%;
  }
}