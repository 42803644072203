/*********************** Home css ****************************/


/* Contact us CSS */


.hr-line {
        width: 100%;
        height: 2.5px;
        background-color: black;
        position: relative;
        z-index: 2;
}

.about-div {
    margin: .5rem 1rem;
    margin-bottom: 0;
}

.cc-div {
    display: flex;
    justify-content: space-between;
}

.help-div {
    margin: .5rem 1rem;
    margin-bottom: 0;
}

.title {
    font-weight: bolder;
}
.chevron2 {
    scale: 1.2;
    transition: all .5s;
    /* cursor: pointer; */
}

.chevron1 {
    scale: 1.2;
    transition: all .5s;
    cursor: pointer;
    transform: rotate(180deg);
}

.cont-form {
    gap: .5rem;
    margin: 1.5rem;
    max-width: 500px;
    width: 90%;

}

.form-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* flex: 1; */
}
.input-box {
    height: 2.7rem;
    padding: 0 1rem;
    outline: none;
    border: 2px solid gray;
    border-radius: 10px;
    border: none;
    font-weight: 500;
    /* outline: 2px solid rgb(255, 255, 178); */

}


.button-1 {
    height: 2.5rem; 
    background-color: #ffd700;
    color: rgb(58, 78, 255);
    font-weight: 700;
    height: 2.4rem;
    border-radius: 10px;
    
}

@media (min-width : 300px ) {
   .swiper-scrollbar {
    margin-top: 1rem;
}

#standard-basic:focus , #standard-basic:valid {
  outline: white !important;
  border-bottom: white !important;
}

}

.contact-div {
    background: linear-gradient(180deg, rgba(217, 217, 217, 0.03) 0%, rgba(217, 217, 217, 0.06) 100%);
}

.css-440q0::after {
    border-bottom: 2px solid white;
}

.css-440q0.Mui-focused::after {
    transform: scale(1) translate(0px);
}

.css-1hsbo08-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid white !important;
}


