@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin:0;
  padding: 0;
  font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.f-cards {
  @apply rounded-3xl
}

  .up-move {
    @apply hover:translate-y-[-.8rem] hover:drop-shadow-xl transition-all duration-500 ;
  
}
