.App {
    /* background-image: linear-gradient(rgb(253, 247, 143), rgb(255, 34, 0)); */
    margin: 0;
}

.spinner {
    margin: 30vh auto 0 auto;
    z-index: 19 !important;
    animation: loading-bar-spinner 100ms linear infinite;
}

/* #loading-bar-spinner.spinner .spinner-icon {
    width: 40px;
    height: 40px;
    border:  solid 4px transparent;
    border-top-color:  #05a794 !important;
    border-left-color: #05a794 !important;
    border-radius: 50%;
}

@keyframes loading-bar-spinner {
  0%   { transform: rotate(0deg);   transform: rotate(0deg); }
  100% { transform: rotate(360deg); transform: rotate(360deg); }
}  */